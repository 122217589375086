import styled from "styled-components";

export const CarouselSection = styled("section")`
	*background:red; 
	padding:0 0rem; 
	
	.leftDiv  {
	   float: left;
	   width: 100%;
		@media (max-width: 400px) {
			height:32vh;
		}
   }
   

.site-button {
  outline: none;
  color: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-table;
  background-color: #1967d2;
  border: none;
  font-size: 14px;
  font-weight: 600;
  transition: 0.5s all ease;
  @media (max-width: 400px) {
	font-size: 10px;
	  padding: 7px 14px;		
  }
}
.site-button:hover {
  color: #000;
}
.site-button i {
  padding-right: 10px;
}
 .twm-read-more .site-button {
  color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s ease !important;
  text-shadow: 0 0 0 #1967d2, 400px 0 0 #1967d2;
  background-color: #fff;
}
 .twm-read-more .site-button:hover {
  text-shadow: -400px 0 0 #1967d2, 0 0 0 #1967d2;
}
.twm-read-more .site-button:hover i {
  text-shadow: none;
}
 .twm-read-more .site-button i {
  color: #1967d2;
  padding-right: 0px;
  padding-left: 5px;
  font-size: 24px;
  text-shadow: none;
}

 .twm-read-more .site-button {
  background-color: #ffb03c;
  color: #fff;
  text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
}
 .twm-read-more .site-button:hover {
  #text-shadow: -400px 0 0 #fff, 0 0 0 #fff;
  text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
  background-color: #ff825c; color:#ffffff; font-weight:bolder;
}
 .twm-read-more .site-button i {
  color: #1967d2;
  padding-right: 0px;
  padding-left: 5px;
  font-size: 24px;
}
   .center{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
   .colclass{background:gold;}
      .colclass1{background:goldenrod;}

   .carouseltitle{
   	font-size:45px; font-weight:bold; color:#074361;
   	font-family: "Motiva Sans Bold", serif;  
   	color: #f5a98f;
   margin-top: -5rem;
@media (max-width: 400px) {
		margin-top: -10rem;
		
	}
   }  
    
   .carouseltitle>h1{color:#a5eb07 !important;}

.carouseldesc{
   	font-size:20px; font-weight:bold; color:#074361;
   	font-family: "Motiva Sans light", serif;  
   	color: #cbc3d6; white-space:pre;	
   }   
   .top-title{
   	font-size:50px; font-weight:bold; color:#638300;white-space:nowrap;text-align:left;background:#ececec;
   	font-family: "Motiva Sans Bold", serif;   	
   }
   
   .justifyy{
   		text-align:justify;padding:10px;color:#d39338;
   		*font-family:"Motiva Sans bold", sans-serif !important;
   }
   	.rightDiv  {
   float: left;
   position:absolute;
   margin-top:14%;
   left:6%;
   *width: 50%;
   

   }
	@media only screen and (max-width: 768px) {
    .rightDiv { margin-top:5rem;}    
    .carouseldesc{
   	font-size:12px; 
   	}
  }	
	.slick-prev::before, .slick-next::before{
		color:#808080
	}
.slick-prev{
	position: absolute;
left: 3%;
top: 50%;
z-index: 998;
}

.slick-next{
	position: absolute;
right: 3%;
top: 50%;
z-index: 998;
}
	.ant-carousel .slick-dots li button {
		background:#698808;
	}

`;





