import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  position: sticky;
  top: 0;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  animation: 0.95s ease 0s normal forwards 1 running mainBar_on_scrollAnimatio;
overflow: hidden;
background: #1b0452;/*#230555;#30065a;#1967d2;*/
z-index:999;

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}


  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
  

 @media only screen and (max-width: 768px) {
    .logomob {width:60%;}
    
  }
  .mya2{width:150px;}
`;




export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;
  color:#fff;

  svg {
    fill: #fff;/*#2e186a;*/
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color:#fff;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  font-weight:bolder;
  color: #FFF;
  transition: color 0.2s ease-in;
  margin: 0.5rem 0.7rem;*2rem;
  .activeClass {
	  border-bottom: 3px solid #fe7624;
  }

  @media only screen and (max-width: 768px) {
    *margin: 1rem 2rem;text-align:left;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px; color:#fff;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) solid underline;
    text-decoration-thickness:0.1rem;
  }
  a {color: #fff;
  	  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) solid underline;
    text-decoration-thickness:0.1rem;
  }
 
 .mya {background: "#698808";
  color: "#fff";
  font-size: 1rem;
  font-weight: 700;
  width: 200px;
  border: 1px solid #edf3f5;
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  z-index:999;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid rgb(255, 130, 92);
    background-color: rgb(255, 130, 92);
  }
  }

  }
`;
