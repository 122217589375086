const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    metatags:["Home"],
  },
  {
    path: ["/privacy"],
    exact: true,
    component: "Privacy",
    metatags:["Home"],
  },
  {
    path: ["/rfq"],
    exact: true,
    component: "Rfq",
    metatags:["Home"],
  },
  {
    path: ["/terms"],
    exact: true,
    component: "Terms",
    metatags:["Home"],
  },
  {
    path: ["/products"],
    exact: true,
    component: "Products",
    metatags:["Home"],
  },
  {
    path: ["/services"],
    exact: true,
    component: "Services",
    metatags:["Home"],
  },
  {
    path: ["/ecargo"],
    exact: true,
    component: "Ecargo",
    metatags:["Home"],
  },
  {
    path: ["/hris"],
    exact: true,
    component: "Hris",
    metatags:["Home"],
  },
  {
    path: ["/rms"],
    exact: true,
    component: "Rms",
    metatags:["Home"],
  },
  {
    path: ["/remit"],
    exact: true,
    component: "Remit",
    metatags:["Home"],
  },
];

export default routes;
