import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.min.css";

import Router from "./router";
import i18n from "./translation";


const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
       <Helmet>
                <title>6iinfotech Car Rental System Home page</title>
                <meta name="description" content="FMS, Track, Logistics, Parcel, TMs, Remittance"  />
                <meta name="keywords"    content="Global Payroll Software | HRMS | Labour & Shift Management Software | ERP Software | Money Remittance Software - 6i-InfoTech | FMS | Logistics | Parcel Services | FTL | LTL | Tracking | FMCG | Car Rental Software | Car Rental App | Chatbot, Logistics ERP | Logistics SAAS"   />
        </Helmet>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
