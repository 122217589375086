import React, {  } from "react";
import { useState } from "react";
import {  Col } from "antd";
import { withTranslation } from "react-i18next";
//import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
//import { Carousel } from 'antd';
import { Slide } from "react-awesome-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
//import { Icon } from '@iconify/react';

import { Button } from "../../common/Button";
import {
  CarouselSection,
} from "./styles";

/* const scrollTo3 = (id: string) => {
	
    // let element = document.getElementById(id) as HTMLDivElement;
     console.log("AAA"+id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };*/
  /* {
      "title": "",
      "titlescroll":"product",
      "hassubtitle": false,
       "subtitle": [],
      "imgname": "saas.jpg",
      "bgclass": "cat-bg-clr-2",
      "btnurl": "saas"
    }*/
const Carouseldiv = ({ section, t }: any) => {
	const [visible, setVisibility] = useState(false);
	const [selected, setSelected] = useState("intro");

 const scrollTo3 = (id: string) => {
 console.log("AAA"+id);
      setSelected(id);      
      const element = document.getElementById(id) as HTMLDivElement;

      if(element == null) {
      	
      	/*if(id.indexOf("/"))
      		document.location.href =""+id;
      	else */
	      	document.location.href ="/#"+id;
      	return false;
      }
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
      
      var _ele;      
      _ele = document.getElementById("spnintro") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnservice") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnproduct") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnrequestdemo") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnabout") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spncontact") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      
      _ele = document.getElementById("spn"+id) as HTMLDivElement;
      _ele.classList.add("activeClass"); 
      
      
    };
   const settings = {
      dots:true,
      infinite:true,

      speed:3000,
      slidesToShow:1,
      autoplay:true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      lazyLoad: true,

       	fade: true,

      slidesToScroll: 1,adaptiveHeight:false,
      responsive: [
      {
        breakpoint: 20,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 80,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      ]
    };
  return (
    <CarouselSection id="intro1">	

    <div className="carousel">
            <Slider {...settings} >

            {typeof section === "object" &&
           section.map((item: any, id: number) => {

           
             return (
		 <Col lg={24} md={24} sm={24} xs={24}  >
		   <div className="amu1">
			<div className={`twm-media  ${item.bgclass} leftDiv`}>
				<SvgIcon src={`${item.imgname}`} width="100%" height="100%" />
			</div>
			
			  <div className="rightDiv">
			  <Slide direction="left"><div className="carouseltitle"><h1>{item.title}</h1></div></Slide>
			  <Slide direction="up">

			  <ul>
			  {typeof item.subtitle === "object" &&
		           item.subtitle.map((itemli: any, id: number) => {
		           return (<li className="carouseldesc ">{itemli.subtitle}</li>
		           );
		           })}
		           </ul>
		           <div className="twm-read-more"><a className="site-button" href={item.titlescroll}>Read More</a></div>
		           </Slide>
		     </div>
		     
		   </div>
		</Col>
		);
           })}
                       </Slider>            
          </div>
           
            
         

    </CarouselSection>
  );
};
/*lsof -i -P -n | grep "3000"*/
export default withTranslation()(Carouseldiv);
